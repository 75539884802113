import {useEffect} from 'react'
import apiClient from '../http-common';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { setAuthStatus } from './authSlice';
import { useSelector } from 'react-redux';


export const CheckAuth = () => {
    const isAuth = useSelector((state) => state.auth.isAuthenticated);

    const dispatch = useDispatch();
    const { isLoading: ischeckingAuth, refetch: checkAuth } = useQuery(
        "check-auth",
        async () => {
            if (!isAuth) {
                return await apiClient.get("/api/check-auth");  
            }
          
        },
        {
          enabled: true,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                dispatch(setAuthStatus(res.data.message))
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );

      useEffect(() => {
        checkAuth();
      }, []);
 
}

export default CheckAuth
