import * as React from 'react';
import { useEffect, Fragment } from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { useSelector } from 'react-redux';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Rider from './Rider';
import Online from './Online';
import Offline from './Offline';
import Editor from './Editor';
import Admin from './Admin';

export default function SideBar() {
  const user = useSelector((state) => state.user.user);
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const userRole = useSelector((state) => state.user.currentRole);

  const addressText = (address) => {
    let text = String(address) || address;
    let last6 = text.slice(text.length - 4);
    let first3 = text.substring(0,3);
    return first3+'***'+last6;
  }

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  useEffect(() => {
    window.addEventListener(
      "toggleSideBar",
      (e) => {
       setState({ ...state, ['left']: true });
      },
      false
    );
    
  }, []);
  const list = (anchor) => (
    <Box
      sx={{ width: 250}}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {(user.address && isAuth) && 
         <Link to='/profile' component={RouterLink} sx={{ width: '100%', textDecoration: 'none', color: 'inherit', maxWidth: 360, bgcolor: 'background.paper' }}>
         <ListItem alignItems="flex-start">
         <ListItemAvatar>
           <Avatar alt={user.address} src={user.profile} />
         </ListItemAvatar>
         <ListItemText
            primary={addressText(user.address)}
            secondary={`TUD ID: ${user.referral_code}`}
         />
       </ListItem>
     </Link>
          }
        <Divider/>

        {isAuth && user.address ?
            userRole === '' ?
            <Online/>
            : 
            <Fragment>
               {userRole === 'admin' && <Admin/>}      
               {userRole === 'editor' && <Editor/>}
               {userRole === 'dispatch rider' && <Rider/>}              
            </Fragment>
                   
          :
          <Offline/>
          }
    </Box>
  );


  return (
    <div>
          <SwipeableDrawer
            sx={{ zIndex: 2500}}
            anchor={`left`}
            open={state[`left`]}
            onClose={toggleDrawer(`left`, false)}
            onOpen={toggleDrawer(`left`, true)}
          >
            {list(`left`)}
          </SwipeableDrawer>
    </div>
  );
}